import React, { useEffect, useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TablePagination from '@mui/material/TablePagination';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import LastPageIcon from '@mui/icons-material/LastPage';
import { KeyboardArrowRight, KeyboardArrowLeft, GradeRounded, LocalFireDepartmentRounded, LogoDevRounded, InvertColorsRounded, StarsRounded } from '@mui/icons-material';
import { Box, IconButton, Link, Tooltip, Container } from '@mui/material';
import Paper from '@mui/material/Paper';
import Papa from 'papaparse';
import Typography from '@mui/material/Typography';

function TablePaginationActions(props) {
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
        sx={{ padding: '0' }}
      >
        <FirstPageIcon />
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
        sx={{ padding: '0' }}
      >
        <KeyboardArrowLeft />
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
        sx={{ padding: '0' }}
      >
        <KeyboardArrowRight />
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
        sx={{ padding: '0' }}
      >
         <LastPageIcon />
      </IconButton>
    </Box>
  );
}

export default function HoldersTable({ token }) {
  const [holders, setHolders] = useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    (async () => {
      try {
        const response = await fetch(`https://cdn.stx.ninja/prod/holders/${token}-001.csv`);

        if (!response.ok) {
          throw new Error(`Failed to download CSV, status: ${response.status}`);
        }

        const csvText = await response.text();

        const { data: dataArray } = Papa.parse(csvText, { header: false, skipEmptyLines: true });

        for (let i = 0; i < dataArray.length; i++) {
          switch (dataArray[i][0]) {
            case "SP000000000000000000002Q6VF78":
              dataArray[i].push("primary");
              dataArray[i].push("burn");
              break;
            case "SPZ3VWN5GMVPY20HWS2WH10KP4P0R99PWRQP2S4J":
              dataArray[i].push("primary");
              dataArray[i].push("dev");
              break;
            case "SP3K8BC0PPEVCV7NZ6QSRWPQ2JE9E5B6N3PA0KBR9.alex-vault-v1-1":
              dataArray[i].push("primary");
              dataArray[i].push("lp");
              break;
            case "SP17A1AM4TNYFPAZ75Z84X3D6R2F6DTJBDJ6B0YF":
              dataArray[i].push("primary");
              dataArray[i].push("muneeb");
              break;
            default:
              dataArray[i].push("primary");
              dataArray[i].push(null);
              break;
          }        
        }
        
        setHolders(dataArray);
      } catch (error) {
        console.error('Error fetching data:', error);
        setHolders([]);
      }
    })();
  }, [token]);

  return (
    <Paper>
      <TableContainer>
        <Table size="small" aria-label="holders">
          <TableHead>
            <TableRow>
              <TableCell sx={{ maxWidth: '5px', textAlign: 'center'}}><GradeRounded fontSize='small' style={{ verticalAlign: 'middle' }}/></TableCell>
              <TableCell sx={{ maxWidth: '25vw'}}><Typography variant="subtitle1">Address</Typography></TableCell>
              <TableCell align="right"><Typography variant="subtitle1">Amount</Typography></TableCell>
              <TableCell align="right"><Typography variant="subtitle1">%</Typography></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {holders.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => (
              <TableRow
                key={index}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell component="th" scope="row" sx={{ maxWidth: '5px', textAlign: 'center' }}>{
                  row[3] === "burn" ? <Tooltip title="Burn Address"><LocalFireDepartmentRounded fontSize='small' style={{ verticalAlign: 'middle' }} color="warning"></LocalFireDepartmentRounded></Tooltip> : null
                }
                {
                  row[3] === "dev" ? <Tooltip title="Dev"><LogoDevRounded fontSize='small' style={{ verticalAlign: 'middle' }} color="secondary"></LogoDevRounded></Tooltip> : null
                }
                {
                  row[3] === "lp" ? <Tooltip title="Liquidity Pool"><InvertColorsRounded fontSize='small' style={{ verticalAlign: 'middle' }} color="primary"></InvertColorsRounded></Tooltip> : null
                }
                {
                  row[3] === "muneeb" ? <Tooltip title="Muneeb"><StarsRounded fontSize='small' style={{ verticalAlign: 'middle' }} color="secondary"></StarsRounded></Tooltip> : null
                }</TableCell>
                <TableCell sx={{ maxWidth: '25vw'}}>
                  <Typography sx={{ fontSize: '10pt' }} noWrap={true}><Link href={`https://explorer.hiro.so/address/${row[0]}?chain=mainnet`} underline="none" color={row[2]}>{row[0]}</Link></Typography>
                </TableCell>
                <TableCell align="right">{Number(row[1]).toLocaleString()}</TableCell>
                <TableCell align="right">{(Number(row[1]) / 95000000).toFixed(2) + '%'}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Container disableGutters={false} align="right">
        <TablePagination
          rowsPerPageOptions={[25, 50, 100, { label: 'All', value: -1 }]}
          count={holders.length}
          rowsPerPage={rowsPerPage}
          page={page}
          slotProps={{
            select: {
              'aria-label': 'rows per page',
            }
          }}
          labelRowsPerPage=""
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          ActionsComponent={TablePaginationActions}
        />
      </Container>
    </Paper>
  );
}