import './App.css';
import { useState} from 'react';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import HoldersTable from './components/HoldersTable';
import { Stack, Container, Typography, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import STXNinjaLogo from './images/stx-ninja.png'

const darkTheme = createTheme({
  palette: {
    background: {
      default: "#222222"
    },
    mode: 'dark',
  },
});

const tokenImages = {
  leo: "https://token-meta.s3.eu-central-1.amazonaws.com/icon.png",
  welsh: "https://raw.githubusercontent.com/Welshcorgicoin/Welshcorgicoin/main/logos/welsh_tokenlogo.png"
}

const initialToken = "leo";

function App() {
  const [token, setToken] = useState(initialToken);
  const [tokenImage, setTokenImage] = useState(tokenImages[initialToken])

  const handleTokenChange = (event) => {
    setToken(event.target.value);
    setTokenImage(tokenImages[event.target.value]);
  };

  return (
    <ThemeProvider theme={darkTheme}>
      <CssBaseline />
      <Container disableGutters={false}>
        <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2} sx={{ padding: '4px' }}>
          <Stack direction="row" justifyContent="space-between" alignItems="top" spacing={1} sx={{ padding: '4px' }}>
            <img src={STXNinjaLogo} alt="STX Ninja" width="50" height="50" />
            <Typography variant="button">STX Ninja</Typography>
          </Stack>
          <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={1} sx={{ padding: '4px' }}>
              <img src={tokenImage} alt="Leo" width="32" height="32"></img>
              <FormControl fullWidth size="small">
                <InputLabel id="token-select-label">Token</InputLabel>
                <Select
                  labelId="token-select-label"
                  id="token-select"
                  value={token}
                  label="Token"
                  onChange={handleTokenChange}
                >
                  <MenuItem value={'leo'}>Leo</MenuItem>
                  <MenuItem value={'welsh'}>Welsh</MenuItem>
                </Select>
              </FormControl>
          </Stack>
        </Stack>
        <HoldersTable token={token}></HoldersTable>
      </Container>
    </ThemeProvider>
    
  );
}

export default App;
